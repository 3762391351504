<template>
  <div style="width:100%;height:100%">
    <div
      style="position:relative;overflow:hidden;width:100%;height:100%;box-shadow:0 0 0.8rem rgba(0,0,0,0.1);border-radius:10px;font-family:'微软雅黑';-webkit-user-select:none;"
    >
      <div
        v-if="imgShow == 0"
        ref="weatherBack"
        alt="背景"
        style="width:100%;height:100%;position:absolute;left:0;top:0;transition:all 1s;"
        class="weather-back"
      ></div>
      <div
        v-else-if="imgShow == 1"
        ref="weatherBack"
        alt="背景"
        class="weather-back"
        :style="backUrl ? 'width:100%;height:100%;position:absolute;left:0;top:0;transition:all 1s;background-image: url(' + backUrl + ');' : 'width:100%;height:100%;position:absolute;left:0;top:0;transition:all 1s;'"
      ></div>
      <!-- :style="{
          'background-image':
            'linear-gradient(to top,' + weatherSet[weather].bg.begin + ',' + weatherSet[weather].bg.end + ')'
        }" -->
      <svg
        t="1594257719362"
        viewBox="0 0 300 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2212"
        data-spm-anchor-id="a313x.7781069.0.i1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        style="position:relative;"
      >
        <!-- Back Layer -->
        <filter id="filter-back">
          <feTurbulence type="fractalNoise" baseFrequency="0.018" numOctaves="4" seed="2"></feTurbulence>
          <feDisplacementMap in="SourceGraphic" scale="170"></feDisplacementMap>
        </filter>
        <!-- Middle Layer -->
        <filter id="filter-mid">
          <feTurbulence type="fractalNoise" baseFrequency="0.018" numOctaves="2" seed="2"></feTurbulence>
          <feDisplacementMap in="SourceGraphic" scale="150"></feDisplacementMap>
        </filter>
        <!-- Front Layer -->
        <filter id="filter-front">
          <feTurbulence type="fractalNoise" baseFrequency="0.018" numOctaves="2" seed="2"></feTurbulence>
          <feDisplacementMap in="SourceGraphic" scale="100"></feDisplacementMap>
        </filter>

        <filter id="glow" x="-100%" y="-100%" width="400%" height="400%">
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0"></feOffset>
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="25"></feGaussianBlur>
          <feBlend in="SourceGraphic" in2="blurOut" mode="multiply"></feBlend>
        </filter>
        <filter id="thunder-glow" x="-100%" y="-100%" width="400%" height="400%">
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="50"></feGaussianBlur>
        </filter>

<!--         <transition name="slide-fade">
          <g v-if="['sun', 'sun&amp;cloud'].indexOf(weather) > -1">
            <g>
              <circle fill="#f36fff" filter="url(#glow)" cx="240" cy="60" r="20"></circle>
              <circle v-for="i in 6" :key="i" fill="#fefef3" filter="url(#glow)" cx="240" cy="60" r="20"></circle>
              <circle fill="#FFF" cx="240" cy="60" r="20"></circle>
            </g>
            <g>
              <circle
                fill="rgba(255,255,255,0.2)"
                style="stroke:rgba(242,232,255,0.5);"
                cx="120"
                cy="20"
                r="18"
              ></circle>
              <circle fill="rgba(255,255,255,0.2)" cx="138" cy="30" r="10"></circle>
              <circle fill="rgba(255,255,255,0.2)" cx="180" cy="40" r="4"></circle>
              <circle fill="rgba(255,255,255,0.2)" cx="190" cy="40" r="4"></circle>
              <animateTransform
                attributeName="transform"
                begin="0s"
                dur="20s"
                type="rotate"
                rotate="auto"
                values="0 240 60;-20 240 60;0 240 60"
                calcMode="linear"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </g>
        </transition> -->
      </svg>
<!--       <svg
        v-if="['thunder'].indexOf(weather) > -1"
        preserveAspectRatio="xMinYMin"
        viewBox="0 0 300 120"
        style="position:absolute;left:0%;top:0;width:100%;height:100%;"
      >
        <ellipse
          fill="#f1e7ff"
          style="opacity:0;animation:thunder-glow 5s linear 1s infinite"
          filter="url(#thunder-glow)"
          cx="150"
          cy="60"
          rx="300"
          ry="120"
        ></ellipse>
        <ellipse
          fill="#f1e7ff"
          style="opacity:0;animation:thunder-glow 5s linear 4s infinite"
          filter="url(#thunder-glow)"
          cx="150"
          cy="60"
          rx="300"
          ry="120"
        ></ellipse>

        <g style="transform:translateX(150px);">
          <polyline
            style="fill:none;stroke-width:1.2;animation: depict 5s linear 1s infinite;"
            points="56.6 0.26 42.92 23 51.37 23 42.92 41.51 54.19 47.36 42.92 69.13 52.18 74.32 42.92 112.66"
          ></polyline>
          <polyline
            style="fill:none;stroke-width:1;animation: depict-sub 5s linear 1s infinite;"
            points="51.07 78.9 39.74 84.52 40.76 88.78 24.09 91.74 25.51 94.36 0.1 99.29"
          ></polyline>
          <polyline
            style="fill:none;stroke-width:1;animation: depict-sub 5s linear 1s infinite;"
            points="48.82 89.93 54.05 98.96 59.87 97.98 64.83 107.66 72.76 106.02 84.35 120.63"
          ></polyline>
        </g>

        <polyline
          style="fill:none;transform:translateX(80px);stroke-width:1.2;animation: depict 5s linear 4s infinite;"
          points="56.6 0.26 42.92 23 51.37 23 42.92 41.51 54.19 47.36 42.92 69.13 52.18 74.32 42.92 112.66"
        ></polyline>
      </svg> -->
<!--       <template v-if="['cloud', 'sun&amp;cloud'].indexOf(weather) > -1">
        <div style="position:absolute;" class="cloud-main cloud-last">
          <div class="cloud" id="cloud-back"></div>
          <div class="cloud" id="cloud-mid"></div>
          <div class="cloud" id="cloud-front"></div>
        </div>

        <div style="position:absolute;" class="cloud-main">
          <div class="cloud" id="cloud-back"></div>
          <div class="cloud" id="cloud-mid"></div>
          <div class="cloud" id="cloud-front"></div>
        </div>
      </template> -->

<!--       <div
        class="rain"
        style="width:100%;height:100%;pointer-events:none;"
        v-if="['rain', 'thunder'].indexOf(weather) > -1"
      >
        <svg
          v-for="i in 120"
          class="rain__"
          :key="i"
          preserveAspectRatio="xMinYMin"
          viewBox="0 0 10 100"
          :style="
            '--x: ' +
              Math.random() * 100 +
              '; --y: ' +
              Math.random() * 100 +
              '; --o: ' +
              Math.random() * 2 +
              '; --a: ' +
              Math.random() * 2 +
              '; --d: ' +
              Math.random() * 2 +
              '; --s: ' +
              Math.random() * 2 +
              ';'
          "
        >
          <path
            stroke="none"
            d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
          ></path>
        </svg>
      </div> -->

      <!--       <div
        class="weather-info"
        style="position:absolute;left:0;top:0;height:100%;display:flex;align-items:center;padding-left:1rem;"
      >
        <div style="line-height:1.5;transition:color 0.5s;" :style="{ color: weatherSet[weather].color }">
          <div style="font-size:1.5rem;">{{ city }}</div>
          <div style="font-size:0.7rem;opacity:0.7;">{{ weatherSet[weather].name }} {{ temperature }}℃</div>
        </div>
      </div> -->
    </div>

    <!--     <div style="text-align:center;">
      <button @click="weather = 'sun'">晴天</button>
      <button @click="weather = 'cloud'">阴天</button>
      <button @click="weather = 'sun&amp;cloud'">多云</button>
      <button @click="weather = 'rain'">下雨</button>
      <button @click="weather = 'thunder'">雷雨</button>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    weather: {
      type: String,
      default: 'sun'
    }
  },
  data() {
    return {
      city: '深圳',
      imgShow:0,
      temperature: '30',
      backUrl:'',
      weatherSet: {
        sun: {
          bg: {
            begin: 'rgba(175 221 221)',
            end: '#aedeff'
          },
          name: '晴',
          color: '#4f5862'
        },
        cloud: {
          bg: {
            begin: 'rgb(207 227 237)',
            end: 'rgb(109 151 179)'
          },
          name: '阴天',
          color: '#4f5862'
        },
        rain: {
          bg: {
            begin: 'rgb(207 227 237)',
            end: 'rgb(109 151 179)'
          },
          name: '雨天',
          color: '#FFF'
        },
        thunder: {
          bg: {
            begin: '#c9d3db',
            end: '#566874'
          },
          name: '雷电',
          color: '#FFF'
        },
        'sun&cloud': {
          bg: {
            begin: 'rgba(174 195 195)',
            end: '#aedeff'
          },
          name: '多云',
          color: '#4f5862'
        }
      }
    }
  },
  methods: {
    changeBack(data) {
      /* let num = Math.floor(Math.random() * 3) */
      if(data.user&&data.user.backUrl){
        this.imgShow=1
        this.backUrl=data.user.backUrl
      }else{
        this.imgShow=0
      }
      
    }
  }
}
</script>

<style scoped>
.weather-back {
  background-image: url('~@/assets/tianqi0.jpg');
  background-size: 100% 100%;
  background-repeat: repeat;
}
.weather-back1 {
  background-size: 100% 100%;
  background-repeat: repeat;
}
.weather-back2 {
  background-image: url('~@/assets/tianqi2.jpg');
  background-size: 100% 100%;
  background-repeat: repeat;
}
.cloud {
  width: 300px;
  height: 205px;
  border-radius: 50%;
  position: absolute;
  top: -35vh;
  left: -25vw;
  transition: all 1s;
}
.cloud-main {
  animation: movetoleft 20s linear infinite;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  pointer-events: none;
  transform: translateX(50%);
}
.cloud-last {
  animation: movetoleft 20s linear 10s infinite;
}
#cloud-back {
  filter: url(#filter-back);
  box-shadow: 300px 300px 60px -20px #fff;
}
#cloud-mid {
  filter: url(#filter-mid);
  box-shadow: 300px 340px 170px -60px rgba(158, 168, 179, 0.5);
  left: -25vw;
}
#cloud-front {
  filter: url(#filter-front);
  box-shadow: 300px 370px 160px -100px rgba(0, 0, 0, 0.3);
  left: -25vw;
}
@keyframes movetoleft {
  0% {
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.rain__ {
  -webkit-animation-delay: calc(var(--d) * 1s);
  animation-delay: calc(var(--d) * 1s);
  -webkit-animation-duration: calc(var(--a) * 1s);
  animation-duration: calc(var(--a) * 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*   -webkit-animation-name: ;
  animation-name: ; */
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 30px;
  left: calc(var(--x) * 1%);
  position: absolute;
  top: calc((var(--y) + 50) * -1px);
}
.rain__ path {
  fill: #a1c6cc;
  opacity: var(--o);
  -webkit-transform: scaleY(calc(var(--s) * 1.5));
  transform: scaleY(calc(var(--s) * 1.5));
}
@-webkit-keyframes fade {
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }
}
@keyframes fade {
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }
}
@keyframes depict {
  0% {
    stroke: #fff;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    opacity: 1;
  }
  100% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    opacity: 1;
  }
}
@keyframes depict-sub {
  0% {
    stroke: #fff;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
  }
  1% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes thunder-glow {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  8% {
    opacity: 0;
  }
  9% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.slide-fade-enter-active {
  animation: bounce-in 1s;
}
.slide-fade-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0) translateX(100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}
</style>
